<template>
  <div class="navigation-container">
    <div class="container">
      <div class="navigation-body">
        <div class="navigation-body-element title">
          <h1>
            {{ title }}<small show-if="subtitle">{{ subtitle }}</small>
          </h1>
        </div>
        <div class="navigation-body-element accessory">
          <button
            v-show="showsOverflowButton"
            v-on:click="$emit('overflowButtonSelected')"
            class="overflow"
          ></button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["title", "subtitle", "showsOverflowButton"]
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/_vars.scss";
@import "../assets/scss/media.scss";

.navigation-container {
  display: block;
  width: 100%;
  padding: 7px 0;
  background: white;
  border-bottom: 1px solid #ebebeb;

  h1 {
    font-size: 18px;
    color: #323d4e;
    font-weight: 700;
    letter-spacing: -0.27px;
    display: block;

    small {
      font-size: 9px;
      color: #9aa1ad;
      font-weight: 700;
      letter-spacing: 1.34px;
      display: block;
      text-transform: uppercase;
      margin-top: 6px;
    }
  }

  button.overflow {
    display: inline-block;
    width: 26px;
    height: 26px;
    border: none;
    outline: none;

    background: none;
    background-image: url("/assets/images/navigation-overflow-button.png");
    background-repeat: no-repeat;
    background-size: 26px 26px;

    transition: all 0.3s;

    &:hover {
      cursor: pointer;
      opacity: 0.9;
    }
  }
}

.navigation-container {
  width: 100%;
}

.navigation-container .navigation-body {
  width: 100%;
  display: grid;
  grid-template-columns: auto auto;
  grid-template-areas: "title accessory";
}

.navigation-body-element {
  &.title {
    grid-area: title;
    justify-self: start;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%; // Ensure the element takes full height of its grid cell
  }
  &.accessory {
    grid-area: accessory;
    justify-self: end;
    align-self: center;
  }
}

@media (prefers-color-scheme: dark) {
  .navigation-container {
    background: #141414;
    border-bottom: none;

    h1 {
      color: white;
    }

    button.overflow {
      background-image: url("/assets/images/navigation-overflow-dark.png");
    }
  }
}
</style>
