<template>
  <div class="footer-container">
    <footer>
      <span>
        Uploaded with
        <a href="https://dropoverapp.com"
          ><img :src="image" /><span class="dropover">Dropover</span></a
        >
      </span>
    </footer>
  </div>
</template>

<script>
export default {
  computed: {
    image: function() {
      return "../assets/images/footer-icon.png";
    }
  }
};
</script>

<style lang="scss" scoped>
footer {
  position: relative;
  text-align: center;

  font-size: 12px;
  font-weight: 600;
  color: #9aa1ad;
}

footer span {
  display: inline-block;
  padding: 6px 8px;
  margin-left: 2px;
}

footer span.dropover {
  font-weight: 600;
  top: -1px;
}

footer span img {
  position: relative;
  display: inline-block;
  width: 28px;
  height: 28px;
  top: 9px;
  left: 4px;
}

.footer-container {
  display: block;
  width: 100%;
  text-align: center;
  padding: 20px;
  margin-top: 100px;

  footer {
    padding: 0;
    margin: 0;
  }
}
</style>
